.universeBackground {
	background-color: black;
	min-width: 365px;
	width: 100vw;
	justify-content: center;
	.landingPage {
		margin: 0 auto;
		@media screen and (min-width: 1350px) {
			max-width: 1350px;
			min-width: 1350px;
		}

		// @media screen and (max-width: 1350px) {
		// 	background-image: url("../../../assets/svg/authSuppliersPortal/authSupplierPortalBackground4.svg");
		// 	background-repeat: no-repeat;
		// }
	}

	// @media screen and (min-width: 1350px) {
	// 	background-image: url("../../../assets/svg/authSuppliersPortal/authSupplierPortalBackground.svg");
	// 	background-size: 6200px;
	// 	background-repeat: no-repeat;
	// 	background-position-y: 0;
	// 	background-position-x: center;
	// }
}

.responsivePlanetBackground {
	width: 100%;

	// @media screen and (max-width: 1350px) {
	// 	background-image: url("../../../assets/svg/authSuppliersPortal/authSupplierPortalBackground2.svg");
	// 	background-position: 0px 300px;
	// 	background-repeat: no-repeat;
	// 	background-size: auto;
	// 	width: 100%;
	// }

	@media screen and (max-width: 768px) {
		background-position: 0px 125%;
		background-size: 180% 85%;
	}
	@media screen and (max-width: 650px) {
		background-size: 220% 85%;
	}
	@media screen and (max-width: 580px) {
		background-size: 240% 85%;
	}
	@media screen and (max-width: 550px) {
		background-size: 280% 85%;
	}
	@media screen and (max-width: 480px) {
		background-size: 320% 85%;
	}
	@media screen and (max-width: 440px) {
		background-size: 480% 85%;
		background-position: 0px 120%;
	}
	@media screen and (max-width: 410px) {
		background-size: 400% 85%;
		background-position: 0px 120%;
	}
	@media screen and (max-width: 390px) {
		background-size: 400% 85%;
		background-position: 0px 100%;
	}
	@media screen and (max-width: 364px) {
		background-size: 400% 85%;
		background-position: 0px 90%;
	}
	@media screen and (max-width: 330px) {
		background-size: 400% 85%;
		background-position: 0px 70%;
	}
}

body {
	scrollbar-width: none !important;
	-ms-overflow-style: none !important;
	&::-webkit-scrollbar {
		display: none !important;
	}
}
