// MainGrid
@supports (grid-area: auto) {
  @media screen and (min-width: 56em) {
    .mainContainer {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr;
      grid-template-areas:
        "header"
        "main"
        "footer";

      @media screen and (min-width: 70em) {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        grid-template-areas:
          "header"
          "main"
          "footer";
      }

      & > div {
        display: flex;
      }
    }

    .mainContainerHeader {
      grid-area: header;
    }

    .mainContainerContent {
      grid-area: main;

      flex-direction: column;
      justify-content: flex-start;
      overflow-y: auto;
      overflow-x: hidden;
      width: 100%;

      border-radius: 1rem;
      padding: 4rem 8rem;

      @media screen and (max-width: 1100px) {
        padding: 2rem 4rem;
      }
    }

    .mainContainerFooter {
      grid-area: footer;
    }
  }
}

// Form
.formActions {
  display: flex;
  justify-content: flex-end;
  button {
    margin: 0;
  }
}
