.sectionContainer {
  display: flex;
  flex-direction: column;

  .titleContainer {
    max-width: 80%;
  }

  .tableContainer {
    background-color: white;
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.14),
      0px 1px 18px rgba(0, 0, 0, 0.12), 0px 3px 5px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    margin-top: 2rem;

    table {
      thead {
        border-top: 1px solid #ced4da;
        border-left: 1px solid #ced4da;
        border-right: 1px solid #ced4da;
        th {
          background: #f3f3f3;
          padding: 0.7rem;

          span {
            color: #354c80;
          }
        }
      }
    }

    .headerTable {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-top: 1px solid #ced4da;
      border-left: 1px solid #ced4da;
      border-right: 1px solid #ced4da;
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
      padding: 1.3rem 2.2rem;

      // Temporary
      & > div {
        text-align: right;
      }

      .amountCounties {
        color: #146ff8;
        font-weight: bold;
        font-size: 1.1rem;
      }

      .headerTableLeft {
        display: flex;

        & > div {
          margin-right: 1rem;
        }
      }
    }

    .footerTable {
      padding: 1.3rem 1.2rem;
      border-bottom: 1px solid #ced4da;
      border-left: 1px solid #ced4da;
      border-right: 1px solid #ced4da;
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
    }
  }
}
