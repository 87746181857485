.identifierTag {
  justify-self: center;
  width: fit-content;
  white-space: nowrap;
  letter-spacing: 4.5px;
  font-size: 0.999rem;
  font-family: var(--font-family);
  color: var(--color-white);
  margin-bottom: 1.5rem;
  font-weight: 900;
  text-transform: uppercase;

  &:after {
    margin-top: 2px;
    content: "ﾠ";
    display: flex;
    background-image: linear-gradient(to right, #cd27c6, #0a9aec);
    float: bottom;
    width: 100%;
    height: 3px;
  }
}
