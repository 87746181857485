* {
  box-sizing: border-box;
}

*:focus {
  outline: none;
}

body {
  background: #f3f3f3;
  font-family: "Mulish", sans-serif !important;
  color: #464555;
  height: auto !important;
  overflow-x: hidden !important;

  scrollbar-width: thin;
  scrollbar-color: #bbc0c7 transparent;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #bbc0c7;
    border-radius: 2rem;
    border: 3px solid transparent;
  }
}

body.lightTheme {
  background: #f3f3f3;
  color: #464555;
}

body.darkTheme {
  background: #1c1c1e !important;
  color: #f3f3f3 !important;
}
