$columnsMax: 12;

/* ROW */
.grid_row_wrapper {
	display: flex;
	justify-content: flex-start;
	flex-wrap: wrap;
	flex-direction: row;
}

// Spacing
@for $i from 1 through 3 {
	.grid_row_spacing_y_#{$i} {
		margin: ($i * 8) + px 0 ($i * 8) + px 0;
	}
}

/* COLUMN */
.grid_column_wrapper {
	width: 100%;
	flex: 1 1 0;

	&:last-child {
		padding: 0;
	}

	& > div > div {
		width: 100%;
	}
}

// count
.grid_column_count_0 {
	// Auto
	flex-basis: calc(12 / 1 / 12 * #{100%}) !important;
}

@for $i from 1 through 6 {
	.grid_column_count_#{$i} {
		flex-basis: (12 / $i / 12 * 100%) !important;
	}
}

// Spacing
@for $i from 1 through 3 {
	.grid_column_spacing_x_#{$i} {
		padding: 0 ($i * 8) + px 0 0;
	}
}

// Widths
.grid_column_widths_auto {
	width: auto;
	flex: auto;
}

@for $i from 1 through $columnsMax {
	.grid_column_widths_#{$i} {
		width: ($i / 12 * 100%) !important;
		flex: none !important;

		@media (max-width: 480px) {
			padding: 0;
		}
	}
}

// Special Cases

.grid_column_empty {
	flex-basis: 0% !important;
	flex: 0;
}
