.footerBackground {
  width: 100vw;
  background-color: #1c1c1e;
  display: flex;
  justify-content: center;
  min-width: 365px;
  padding-left: 7.5rem;
  padding-right: 7.5rem;

}

.footerBackgroundSimple {
  background-color: #d1dae0;
}

.footerContainer {
  max-width: 1350px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;

  @media screen and (min-width: 1350px) {
    width: 100%;
  }

  @media screen and (max-width: 768px) {
    width: 100vw;
  }
}

.footerContent {
  display: flex;
  justify-content: space-between;
  margin: 2rem 0;
  font-size: 18px;

  p {
    color: var(--color-white);
    @media screen and (max-width: 900px) {
      padding-left: 3rem;
    }
    font-size: 12px;
  }

  img {
    &:last-of-type {
      height: 100px;
    }

    @media screen and (min-width: 900px) {
      &:last-of-type {
        padding-left: 85px;
      }
    }
    @media screen and (max-width: 900px) {
      margin-left: 3rem;
      &:first-of-type {
        width: 20%;
      }
    }
  }

  @media screen and (max-width: 900px) {
    margin: 2rem 2rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    align-items: flex-start;
    img {
      &:last-of-type {
        display: none;
      }
    }
  }
}

.footerContentSimple {
  display: flex;
  justify-content: flex-start;
  margin: 2rem 0;
  font-size: 18px;

  p {
    color: var(--color-white);
    @media screen and (max-width: 900px) {
      padding-left: 3rem;
    }
    font-size: 12px;
  }

  img {
    &:last-of-type {
      height: 100px;
    }

    @media screen and (min-width: 900px) {
      &:last-of-type {
        padding-left: 85px;
      }
    }
    @media screen and (max-width: 900px) {
      margin-left: 3rem;
      &:first-of-type {
        width: 20%;
      }
    }
  }

  @media screen and (max-width: 900px) {
    margin: 2rem 2rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    align-items: flex-start;
    img {
      &:last-of-type {
        display: none;
      }
    }
  }
}

.footerNavigation {
  display: flex;
  justify-content: space-between;
  margin: 2rem 0;

  p {
    color: var(--color-white);
    min-width: 200px;
    width: 270px;
    line-height: 2rem;
  }

  ul {
    list-style: none;
    padding: 0;

    li {
      color: var(--color-white);
      margin: 1.3rem 0;
    }
  }

  h2 {
    letter-spacing: 4.5px;
    font-size: 0.9rem;
    font-family: var(--font-family);
    color: var(--color-white);
    margin-bottom: 2.5rem;

    &:after {
      margin-top: 2px;
      content: "ﾠ";
      display: flex;
      background-image: linear-gradient(to right, #cd27c6, #0a9aec);
      float: bottom;
      width: 21rem;
      height: 3px;
    }
  }

  img {
    display: none;
  }

  :last-of-type h2 {
    &:after {
      width: 11rem;
    }
  }

  @media screen and (max-width: 1350px) {
    div {
      padding-left: 3rem;
    }
  }

  @media screen and (max-width: 900px) {
    width: 90%;
    padding: 0;
    flex-direction: column;
    margin: 0;

    p {
      width: 100vw;
      padding: 0 5rem;
    }

    div {
      margin: 2rem 2rem;
    }

    img {
      display: block;
      padding: 0 5rem;
      width: 85vw;
    }
  }

  @media screen and (max-width: 440px) {
    h2 {
      &:after {
        width: 13rem;
      }
    }
  }
}

.footerNavigationSimple {
  & > p {
    width: 100%;
    font-size: 1.1rem;
    font-weight: 500;
  }
}
