.headerBackground {
  width: 100vw;
  height: 6rem;
  z-index: 98;
  display: flex;
  justify-content: center;
  border-bottom: 0.2rem solid #d6d6d6;
}

.headerContent {
  min-width: 365px;
  position: fixed;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 8rem;
  align-items: center;
  z-index: 99;

  img {
    width: 7rem;
    height: 6rem;
  }

  @media screen and (max-width: 1350px) {
    width: 100vw;
  }

  @media screen and (max-width: 768px) {
    width: 100vw;
    padding: 0;
    justify-content: space-around;
  }
}

.nav_ul {
  width: 600px;
  list-style: none;
  justify-content: space-between;
  display: flex;
  align-items: center;

  li {
    color: var(--color-white);
  }

  button {
    background-color: transparent;
    color: var(--color-white);
    border: 1px solid var(--color-white);
    border-radius: 3rem;
    padding: 0.5rem 2rem;
    font-size: 1rem;
    font-weight: 600;
    transition: 0.5s;
    &:hover {
      transition: 0.2s;
      color: #0a9aec;
      border-color: #0a9aec;
      cursor: pointer;
    }
  }

  @media screen and (max-width: 768px) {
    width: auto;

    li {
      display: none;
    }

    :last-child {
      display: flex;
    }
  }
}

.onScroll {
  & > div {
	background: #f3f3f3;
    transition: background-color 0.5s;
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.07), 0px 1px 18px rgba(0, 0, 0, 0),
      0px 3px 5px rgba(0, 0, 0, 0);
  }
}
