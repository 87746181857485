.wrapper {
	&::after {
		content: "";
		z-index: 10;
		width: 100%;
		height: 100%;
		background-color: rgba(255, 255, 255, 0.9);
		border-radius: 100px;
		overflow: hidden;
	}
}

.wrapper_blocked {
	@extend .wrapper;

	&::after {
		position: absolute;
	}
}

.content {
	z-index: 11;

	overflow: hidden;

	width: 100%;
	padding: 1rem 2rem 2rem 2rem;

	display: flex;
	justify-content: center;
	align-items: center;

	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}

.content_blocked {
	@extend .content;

	position: absolute;
}

.content_text_bottom {
	flex-direction: column;

	text-align: center;
	text {
		max-width: 300px;
	}
	p {
		max-width: 300px;
		margin: 0 auto;
		margin-bottom: 15px;
	}
}

.information_session {
	margin-left: 30px;
	max-width: 50%;

	p {
		color: var(--color-grey-800);
		line-height: 150%;
	}

	button {
		margin: 0;
	}
}
